import React  from 'react';
import './App.css';
  
import { useNavigate } from "react-router-dom";

class UserPassword extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			password: "",
		};
		
		this.onPasswordChanged = this.onPasswordChanged.bind(this);		
		this.serverUpdatePassword = this.serverUpdatePassword.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount(){
	}

	onPasswordChanged(value) {
		this.setState({password: value});
	}
	
	async serverUpdatePassword(userID, newPassword) {

		var baseURLAPI = this.props.baseURLAPI;
		var sessionID = this.props.sessionID;
		var url = baseURLAPI + "/updateuserpassword?sid=" + sessionID;
		url = url + "&userid=" + encodeURI(userID);
		url = url + "&password=" + encodeURI(newPassword);
		
		//console.log("url = " + url);

		const res = await fetch(url);
		await res.text();
	}
		
	onClickSaveButton() {
		this.serverUpdatePassword(this.props.selectedUserID, this.state.password);
		const navigate = this.props.navigate;
  		navigate("/users/info");
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/users/info");
	}
	
	render() {

		var managementAppUserType = this.props.managementAppUserType;
		if (managementAppUserType < 100) {
			return null;
		}

		var password = this.state.password;
		
		return (
			<div className="ViewContainer">
			
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">Update user's password</div>
				<div className="ViewTopText">Update password and press Save button</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Set new password</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewItemContainer">
						<div style={{height: "20px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">Password</legend> 
							<input
								className="ViewItemInput" 
								id="password" 
								type="text" 
								autoFocus={true}
								defaultValue={password}
								onChange={(event) => this.onPasswordChanged(event.target.value)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Save</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function UserPasswordFunction(props) {
	const navigate = useNavigate();
	return <UserPassword {...props} navigate={navigate} />;
}


