import React  from 'react';
import './App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";

class UserNew extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			email: "",
			password: "",
			firstName: "",
			lastName: "",
			phone: "",
		};

		this.onEmailChanged = this.onEmailChanged.bind(this);
		this.onPasswordChanged = this.onPasswordChanged.bind(this);
		this.onFirstNameChanged = this.onFirstNameChanged.bind(this);
		this.onLastNameChanged = this.onLastNameChanged.bind(this);
		this.onPhoneChanged = this.onPhoneChanged.bind(this);
		this.serverInsertUser = this.serverInsertUser.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	onEmailChanged(value) {
		this.setState({email: value});
	}
	
	onPasswordChanged(value) {
		this.setState({password: value});
	}
	
	onFirstNameChanged(value) {
		this.setState({firstName: value});
	}
	
	onLastNameChanged(value) {
		this.setState({lastName: value});
	}
	
	onPhoneChanged(value) {
		this.setState({phone: value});
	}
	
	async serverInsertUser(email, password, firstName, lastName, phone, companyID, managementAppUserType, sailfinAppUserType, katafinAppUserType) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertuserrecord?email=" + encodeURI(email);
		url = url + "&password=" + encodeURI(password);
		url = url + "&firstname=" + encodeURI(firstName);
		url = url + "&lastname=" + encodeURI(lastName);
		url = url + "&phone=" + encodeURI(phone);
		url = url + "&companyid=" + companyID;
		url = url + "&managementappusertype=" + managementAppUserType;
		url = url + "&sailfinappusertype=" + sailfinAppUserType;
		url = url + "&katafinappusertype=" + katafinAppUserType;
		
		console.log("url = " + url);

		const res = await fetch(url);
		await res.text();

		const navigate = this.props.navigate;
  		navigate("/users");
	}
		
	onClickSaveButton() {

		var selectedCompanyID = this.props.selectedCompanyID;

		if (selectedCompanyID === 0) {
			toast("Cannot create new user - you first need to select a company", {
				position: "top-right",
				autoClose: 10000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			const navigate = this.props.navigate;
			navigate("/companies");
			return;
		}

		this.serverInsertUser(this.state.email, this.state.password, this.state.firstName, this.state.lastName, this.state.phone, selectedCompanyID, 1, 1, 1);
		toast("New user created", {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/companies");
	}
	
	render() {
		
		var managementAppUserType = this.props.managementAppUserType;
		if (managementAppUserType < 100) {
			return null;
		}

		var selectedCompanyName = this.props.selectedCompanyName;
		
		return (		
			<div className="ViewContainer">

				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">New user</div>
				<div className="ViewTopText">Add user info and then proceed to add users</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">

					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Login info</div>
					<div style={{height: "30px"}}></div>
					<div className="ViewItemContainer">
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">Email</legend> 
							<input
								className="ViewItemInput" 
								id="email" 
								type="text" 
								autoFocus={true}
								onChange={(event) => this.onEmailChanged(event.target.value)}
							/>
						</fieldset>
					</div>
					<div style={{height: "10px"}}></div>
					<div className="ViewItemContainer">
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">Password</legend> 
							<input
								className="ViewItemInput" 
								id="password" 
								type="text" 
								onChange={(event) => this.onPasswordChanged(event.target.value)}
							/>
						</fieldset>
					</div>
					<div style={{height: "10px"}}></div>
					<div className="ViewItemContainer">
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">Company</legend> 
							<input
								className="ViewItemInput" 
								id="companyName" 
								type="text" 
								value={selectedCompanyName}
								disabled={true}
							/>
						</fieldset>
					</div>
					

					<div style={{height: "50px"}}></div>
					<div className="ViewBlockTitle">Contact info</div>
					<div style={{height: "30px"}}></div>
					<div className="ViewItemContainer">
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">First name</legend> 
							<input
								className="ViewItemInput" 
								id="userName" 
								type="text" 
								onChange={(event) => this.onFirstNameChanged(event.target.value)}
							/>
						</fieldset>
					</div>
					<div style={{height: "10px"}}></div>
					<div className="ViewItemContainer">
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">Last name</legend> 
							<input
								className="ViewItemInput" 
								id="userName" 
								type="text" 
								onChange={(event) => this.onLastNameChanged(event.target.value)}
							/>
						</fieldset>
					</div>
					<div style={{height: "10px"}}></div>
					<div className="ViewItemContainer">
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">Phone</legend> 
							<input
								className="ViewItemInput" 
								id="userName" 
								type="text" 
								onChange={(event) => this.onPhoneChanged(event.target.value)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Save</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function UserNewFunction(props) {
	const navigate = useNavigate();
	return <UserNew {...props} navigate={navigate} />;
}

/*
*/