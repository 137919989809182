import React  from 'react';
import './App.css';

import { useNavigate } from "react-router-dom";

class UserInfo extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			email: "",
			firstName: "",
			lastName: "",
			phone: "",
			managementAppUserType: 0,
			sailfinAppUserType: 0,
			katafinAppUserType: 0,
			productionAppUserType: 0,
			metersAppUserType: 0,
			icartAppUserType: 0,
			serviAppUserType: 0,
			robotsAppUserType: 0,
			guardsAppUserType: 0,
			twinsAppUserType: 0,
			rentAppUserType: 0,
			develAppUserType: 0,
			aiAppUserType: 0,
			chatAppUserType: 0,
			replyAppUserType: 0,
			companyID: 0,
			createdTimestamp: null,
		};

		this.getStringFromUserType = this.getStringFromUserType.bind(this);
		this.serverGetUserData = this.serverGetUserData.bind(this);
		this.serverUpdatePermissions = this.serverUpdatePermissions.bind(this);
		this.renderOneRow = this.renderOneRow.bind(this);		
		this.handleRowClick = this.handleRowClick.bind(this);				
	}
	
	componentDidMount(){
		this.serverGetUserData();
	}

	getStringFromUserType(userType) {
		
		var returnString = "";
		
		if (userType === 0) {
			returnString = "None";
		}
		
		if (userType === 1) {
			returnString = "User";
		}
		
		if (userType === 100) {
			returnString = "Administrator";
		}
		
		if (userType === 1000) {
			returnString = "Super Administrator";
		}
		
		return returnString;
	}
	
	async serverGetUserData() {

		var selectedUserID = this.props.selectedUserID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getuserdata?userid=" + selectedUserID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var email = jsonObj.email;
		var firstName = jsonObj.firstName;
		var lastName = jsonObj.lastName;
		var phone = jsonObj.phone;
		var managementAppUserType = jsonObj.managementAppUserType;
		var sailfinAppUserType = jsonObj.sailfinAppUserType;
		var katafinAppUserType = jsonObj.katafinAppUserType;
		var productionAppUserType = jsonObj.productionAppUserType;
		var metersAppUserType = jsonObj.metersAppUserType;
		var icartAppUserType = jsonObj.icartAppUserType;
		var serviAppUserType = jsonObj.serviAppUserType;
		var robotsAppUserType = jsonObj.robotsAppUserType;
		var guardsAppUserType = jsonObj.guardsAppUserType;
		var twinsAppUserType = jsonObj.twinsAppUserType;
		var rentAppUserType = jsonObj.rentAppUserType;
		var develAppUserType = jsonObj.develAppUserType;
		var aiAppUserType = jsonObj.aiAppUserType;
		var chatAppUserType = jsonObj.chatAppUserType;
		var replyAppUserType = jsonObj.replyAppUserType;
		var companyID = jsonObj.companyID;
		var createdTimestamp = jsonObj.createdTimestamp;

		this.setState({
			email: email,
			firstName: firstName,
			lastName: lastName,
			phone: phone,
			managementAppUserType: managementAppUserType,
			sailfinAppUserType: sailfinAppUserType,
			katafinAppUserType: katafinAppUserType,
			productionAppUserType: productionAppUserType,
			metersAppUserType: metersAppUserType,
			icartAppUserType: icartAppUserType,
			serviAppUserType: serviAppUserType,
			robotsAppUserType: robotsAppUserType,
			guardsAppUserType: guardsAppUserType,
			twinsAppUserType: twinsAppUserType,
			rentAppUserType: rentAppUserType,
			develAppUserType: develAppUserType,
			aiAppUserType: aiAppUserType,
			chatAppUserType: chatAppUserType,
			replyAppUserType: replyAppUserType,
			companyID: companyID,
			createdTimestamp: createdTimestamp,
		});
	}
	
	async serverUpdatePermissions(userID, field, value) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updateuserpermissions?userid=" + userID;
		url = url + "&field=" + field;
		url = url + "&value=" + value;
		
		const res = await fetch(url);
		await res.text();
		
		this.serverGetUserData();
	}
		
	handleRowClick(page) {

		var selectedUserID = this.props.selectedUserID;

		if (page === "") {
			return;
		}

		if (page === "managementpermissions") {
			var managementAppUserType = this.state.managementAppUserType;
			if (managementAppUserType === 0) {
				managementAppUserType = 1;
			} else {
				if (managementAppUserType === 1) {
					managementAppUserType = 100;
				} else {
					if (managementAppUserType === 100) {
						managementAppUserType = 1000;
					} else {
						if (managementAppUserType === 1000) {
							managementAppUserType = 0;
						}
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "managementAppUserType", managementAppUserType);
			return;
		}

		if (page === "sailfinapppermissions") {
			var sailfinAppUserType = this.state.sailfinAppUserType;
			if (sailfinAppUserType === 0) {
				sailfinAppUserType = 1;
			} else {
				if (sailfinAppUserType === 1) {
					sailfinAppUserType = 100;
				} else {
					if (sailfinAppUserType === 100) {
						sailfinAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "sailfinAppUserType", sailfinAppUserType);
			return;
		}

		if (page === "katafinapppermissions") {
			var katafinAppUserType = this.state.katafinAppUserType;
			if (katafinAppUserType === 0) {
				katafinAppUserType = 1;
			} else {
				if (katafinAppUserType === 1) {
					katafinAppUserType = 100;
				} else {
					if (katafinAppUserType === 100) {
						katafinAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "katafinAppUserType", katafinAppUserType);
			return;
		}

		if (page === "productionapppermissions") {
			var productionAppUserType = this.state.productionAppUserType;
			if (productionAppUserType === 0) {
				productionAppUserType = 1;
			} else {
				if (productionAppUserType === 1) {
					productionAppUserType = 100;
				} else {
					if (productionAppUserType === 100) {
						productionAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "productionAppUserType", productionAppUserType);
			return;
		}

		if (page === "metersapppermissions") {
			var metersAppUserType = this.state.metersAppUserType;
			if (metersAppUserType === 0) {
				metersAppUserType = 1;
			} else {
				if (metersAppUserType === 1) {
					metersAppUserType = 100;
				} else {
					if (metersAppUserType === 100) {
						metersAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "metersAppUserType", metersAppUserType);
			return;
		}

		if (page === "icartapppermissions") {
			var icartAppUserType = this.state.icartAppUserType;
			if (icartAppUserType === 0) {
				icartAppUserType = 1;
			} else {
				if (icartAppUserType === 1) {
					icartAppUserType = 100;
				} else {
					if (icartAppUserType === 100) {
						icartAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "icartAppUserType", icartAppUserType);
			return;
		}

		if (page === "serviapppermissions") {
			var serviAppUserType = this.state.serviAppUserType;
			if (serviAppUserType === 0) {
				serviAppUserType = 1;
			} else {
				if (serviAppUserType === 1) {
					serviAppUserType = 100;
				} else {
					if (serviAppUserType === 100) {
						serviAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "serviAppUserType", serviAppUserType);
			return;
		}

		if (page === "robotsapppermissions") {
			var robotsAppUserType = this.state.robotsAppUserType;
			if (robotsAppUserType === 0) {
				robotsAppUserType = 1;
			} else {
				if (robotsAppUserType === 1) {
					robotsAppUserType = 100;
				} else {
					if (robotsAppUserType === 100) {
						robotsAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "robotsAppUserType", robotsAppUserType);
			return;
		}

		if (page === "guardsapppermissions") {
			var guardsAppUserType = this.state.guardsAppUserType;
			if (guardsAppUserType === 0) {
				guardsAppUserType = 1;
			} else {
				if (guardsAppUserType === 1) {
					guardsAppUserType = 100;
				} else {
					if (guardsAppUserType === 100) {
						guardsAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "guardsAppUserType", guardsAppUserType);
			return;
		}

		if (page === "twinsapppermissions") {
			var twinsAppUserType = this.state.twinsAppUserType;
			if (twinsAppUserType === 0) {
				twinsAppUserType = 1;
			} else {
				if (twinsAppUserType === 1) {
					twinsAppUserType = 100;
				} else {
					if (twinsAppUserType === 100) {
						twinsAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "twinsAppUserType", twinsAppUserType);
			return;
		}

		if (page === "rentapppermissions") {
			var rentAppUserType = this.state.rentAppUserType;
			if (rentAppUserType === 0) {
				rentAppUserType = 1;
			} else {
				if (rentAppUserType === 1) {
					rentAppUserType = 100;
				} else {
					if (rentAppUserType === 100) {
						rentAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "rentAppUserType", rentAppUserType);
			return;
		}

		if (page === "develapppermissions") {
			var develAppUserType = this.state.develAppUserType;
			if (develAppUserType === 0) {
				develAppUserType = 1;
			} else {
				if (develAppUserType === 1) {
					develAppUserType = 100;
				} else {
					if (develAppUserType === 100) {
						develAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "develAppUserType", develAppUserType);
			return;
		}

		if (page === "aiapppermissions") {
			var aiAppUserType = this.state.aiAppUserType;
			if (aiAppUserType === 0) {
				aiAppUserType = 1;
			} else {
				if (aiAppUserType === 1) {
					aiAppUserType = 100;
				} else {
					if (aiAppUserType === 100) {
						aiAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "aiAppUserType", aiAppUserType);
			return;
		}

		if (page === "chatapppermissions") {
			var chatAppUserType = this.state.chatAppUserType;
			if (chatAppUserType === 0) {
				chatAppUserType = 1;
			} else {
				if (chatAppUserType === 1) {
					chatAppUserType = 100;
				} else {
					if (chatAppUserType === 100) {
						chatAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "chatAppUserType", chatAppUserType);
			return;
		}

		if (page === "replyapppermissions") {
			var replyAppUserType = this.state.replyAppUserType;
			if (replyAppUserType === 0) {
				replyAppUserType = 1;
			} else {
				if (replyAppUserType === 1) {
					replyAppUserType = 100;
				} else {
					if (replyAppUserType === 100) {
						replyAppUserType = 0;
					}
				}
			}
			this.serverUpdatePermissions(selectedUserID, "replyAppUserType", replyAppUserType);
			return;
		}

		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderOneRow(title, value, page) {

		var gtString = ">";
		if (page === "") {
			gtString = "";
		}

		return (
				<div className="MyInfoRowContainer" onClick={() => this.handleRowClick(page)}>
					<div className="MyInfoRowTitle">{title}</div>
					<div className="MyInfoRowText">{value}</div>
					<div className="MyInfoRowGreater">{gtString}</div>
				</div>
		);
	}
	
	render() {

		var managementAppUserTypeLoggedInUser = this.props.managementAppUserType;
		if (managementAppUserTypeLoggedInUser < 100) {
			return null;
		}

		var selectedUserID = this.props.selectedUserID;
		var email = this.state.email;
		var firstName = this.state.firstName;
		var lastName = this.state.lastName;
		var phone = this.state.phone;
		var managementAppUserType = this.state.managementAppUserType;
		var sailfinAppUserType = this.state.sailfinAppUserType;
		var katafinAppUserType = this.state.katafinAppUserType;
		var productionAppUserType = this.state.productionAppUserType;
		var metersAppUserType = this.state.metersAppUserType;
		var icartAppUserType = this.state.icartAppUserType;
		var serviAppUserType = this.state.serviAppUserType;
		var robotsAppUserType = this.state.robotsAppUserType;
		var guardsAppUserType = this.state.guardsAppUserType;
		var twinsAppUserType = this.state.twinsAppUserType;
		var rentAppUserType = this.state.rentAppUserType;
		var develAppUserType = this.state.develAppUserType;
		var aiAppUserType = this.state.aiAppUserType;
		var chatAppUserType = this.state.chatAppUserType;
		var replyAppUserType = this.state.replyAppUserType;
		//var companyID = this.state.companyID;
		//var createdTimestamp = this.state.createdTimestamp;
		var managementPermissionsString = this.getStringFromUserType(managementAppUserType);
		var sailfinAppPermissionsString = this.getStringFromUserType(sailfinAppUserType);
		var katafinAppPermissionsString = this.getStringFromUserType(katafinAppUserType);
		var productionAppPermissionsString = this.getStringFromUserType(productionAppUserType);
		var metersAppPermissionsString = this.getStringFromUserType(metersAppUserType);
		var icartAppPermissionsString = this.getStringFromUserType(icartAppUserType);
		var serviAppPermissionsString = this.getStringFromUserType(serviAppUserType);
		var robotsAppPermissionsString = this.getStringFromUserType(robotsAppUserType);
		var guardsAppPermissionsString = this.getStringFromUserType(guardsAppUserType);
		var twinsAppPermissionsString = this.getStringFromUserType(twinsAppUserType);
		var rentAppPermissionsString = this.getStringFromUserType(rentAppUserType);
		var develAppPermissionsString = this.getStringFromUserType(develAppUserType);
		var aiAppPermissionsString = this.getStringFromUserType(aiAppUserType);
		var chatAppPermissionsString = this.getStringFromUserType(chatAppUserType);
		var replyAppPermissionsString = this.getStringFromUserType(replyAppUserType);

		var name = firstName + " " + lastName;

		return (
			<div>

				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">User account info</div>
				<div className="ViewTopText">Info about selected user and permissions</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Login info</div>
					<div style={{height: "20px"}}></div>
					{this.renderOneRow("User ID", selectedUserID, "")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Email", email, "/users/email")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Password", "*****", "/users/password")}
					<div style={{height: "10px"}}></div>
				</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Contact info</div>
					<div style={{height: "20px"}}></div>
					{this.renderOneRow("Name", name, "/users/name")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Phone", phone, "/users/phone")}
					<div style={{height: "10px"}}></div>
				</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Plurato services permissions</div>
					<div style={{height: "20px"}}></div>
					{this.renderOneRow("Management", managementPermissionsString, "managementpermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Sailfin App", sailfinAppPermissionsString, "sailfinapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Rent App", rentAppPermissionsString, "rentapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Katafin App", katafinAppPermissionsString, "katafinapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Production App", productionAppPermissionsString, "productionapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Devel App", develAppPermissionsString, "develapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Chat App", chatAppPermissionsString, "chatapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("AI App", aiAppPermissionsString, "aiapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Reply App", replyAppPermissionsString, "replyapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Meters App", metersAppPermissionsString, "metersapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("iCart App", icartAppPermissionsString, "icartapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Servi App", serviAppPermissionsString, "serviapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Robots App", robotsAppPermissionsString, "robotsapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Guards App", guardsAppPermissionsString, "guardsapppermissions")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Twins App", twinsAppPermissionsString, "twinsapppermissions")}
					<div style={{height: "10px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function UserInfoFunction(props) {
	const navigate = useNavigate();
	return <UserInfo {...props} navigate={navigate} />;
}


