import React from 'react';
import './App.css';
import './Navbar.css';
import { AiOutlineUnorderedList, AiOutlineLogout } from "react-icons/ai"
import { FiUsers } from "react-icons/fi"

import { useNavigate } from "react-router-dom";

class Navbar extends React.Component {

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick(page) {

		const navigate = this.props.navigate;

		if (page === "logout") {
			this.props.logoutClearVariables();
			navigate("/login");
		} else {
			navigate("/" + page);
		}

		this.props.closeNavbar();
	}
	
	render() {

		var navbarOpenYN = this.props.navbarOpenYN;
		if (!navbarOpenYN) {
			return (<div></div>);
		}
		
		var managementAppUserType = this.props.managementAppUserType;

		var displayUserListYN = false;
		if (managementAppUserType >= 100) {
			displayUserListYN = true;
		}

		var displayCompanyListYN = false;
		if (managementAppUserType >= 1000) {
			displayCompanyListYN = true;
		}

		return (
			<div className="Navbar">
				{displayUserListYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("users")}
				>
					<div className="NavbarLinkColumn1">
						<FiUsers style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">Users</div>					  
				</div>)}
				{displayCompanyListYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("companies")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineUnorderedList style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">Companies</div>				  					  
				</div>)}
				{displayUserListYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("dbtouchuser")}
				>
					<div className="NavbarLinkColumn1">
						<FiUsers style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">DBTouch User</div>					  
				</div>)}
				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("logout")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineLogout style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">Logout</div>
				</div>
			</div>
		);
	}
}

export default function NavbarFunction(props) {
	const navigate = useNavigate();
	return <Navbar {...props} navigate={navigate} />;
}

