import React  from 'react';
import './App.css';

import { useNavigate } from "react-router-dom";

import UserListToolbar from './UserListToolbar.js';

class UserList extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			companyID: 0,
			companyName: "",
			jsonObj: null,
			userCount: 0,
			count: 10,
		};

		this.serverGetUserList = this.serverGetUserList.bind(this);
		this.handleSelectUser = this.handleSelectUser.bind(this);
		this.formatDateTimeString = this.formatDateTimeString.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);
	}
	
	componentDidMount() {

		var companyID = this.props.selectedCompanyID;
		var companyName = this.props.selectedCompanyName;
		if (companyID === 0) {
			companyID = this.props.companyID;
			companyName = this.props.companyName;
		}

		this.setState({
			companyID: companyID,
			companyName: companyName,
		});
		var userListIndex = this.props.userListIndex;
		this.serverGetUserList(companyID, userListIndex);
	}
	
	componentDidUpdate() {

		var companyID1 = this.state.companyID;
		var companyName1 = this.state.companyName;

		var companyID2 = this.props.selectedCompanyID;
		var companyName2 = this.props.selectedCompanyName;
		if (companyID2 === 0) {
			companyID2 = this.props.companyID;
			companyName2 = this.props.companyName;
		}

		var userListIndex = this.props.userListIndex;

		if (companyID1 !== companyID2) {
			this.setState({companyID: companyID2});
			this.serverGetUserList(companyID2, userListIndex);
			return;
		}

		if (companyName1 !== companyName2) {
			this.setState({companyName: companyName2});
			return;
		}
	}

	async serverGetUserList(companyID, index) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getuserlist?companyid=" + companyID;
		url = url + "&sortby=email&sortasc=1";
		url = url + "&index=" + index + "&count=" + this.state.count;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		var userCount = jsonObj.length;

		this.setState({
			jsonObj: jsonObj,
			userCount: userCount,
		});
	}
		
	handleSelectUser(userID) {
		this.props.setSelectedUserID(userID);
		const navigate = this.props.navigate;
  		navigate("/users/info");
	}

	formatDateTimeString(timestamp) {
		
		var timestampString;
		
		var dateString = timestamp.substr(0, 10);
		var timeString = timestamp.substr(11, 8);

		timestampString = dateString + " " + timeString;
		
		return timestampString;
	}
	
	handlePrev() {
		var userListIndex = this.props.userListIndex - 10;
		this.props.setUserListIndex(userListIndex);
		this.serverGetUserList(this.state.companyID, userListIndex);
	}

	handleNext() {
		var userListIndex = this.props.userListIndex + 10;
		this.props.setUserListIndex(userListIndex);
		this.serverGetUserList(this.state.companyID, userListIndex);
	}
	
	render() {
		
		const RenderUser = ({ userID, email, firstName, lastName, createdTimestamp }) => (
			<div className="ListRow">
				<div
					className="ListLink"
					onClick={() => this.handleSelectUser(userID)}
				>
					{email}
				</div>
				<div
					className="ListLink"
					onClick={() => this.handleSelectUser(userID)}
				>
					{firstName} {lastName}
				</div>
				<div 
					className="ListLink"
					onClick={() => this.handleSelectUser(userID)}
				>
					{this.formatDateTimeString(createdTimestamp)}
				</div>
			</div>
		);

		var managementAppUserType = this.props.managementAppUserType;
		if (managementAppUserType < 100) {
			return null;
		}

		var jsonObj = this.state.jsonObj;
		
		if (jsonObj === null) {
			return (<div></div>)
		}
		
		var prevYN = false;
		var nextYN = false;
		
		var userListIndex = this.props.userListIndex;
		if (userListIndex > 0) {
			prevYN = true;
		}
		
		if (jsonObj.length === 10) {
			nextYN = true;
		}
		
		var companyName = this.state.companyName;
		
		return (
			<div>
				<UserListToolbar />
				<div className="ListContainer1">
					<div className="ListContainer2">
						<div className="ListRow">
							<div className="ListHeader">&nbsp;</div>
						</div>
						<div className="ListRow">
							<div className="ListHeader">{companyName}</div>
						</div>
						<div className="ListRow">
							<div className="ListHeader">&nbsp;</div>
						</div>
						
						<div className="ListRow">
							<div className="ListHeader">Email</div>
							<div className="ListHeader">Name</div>
							<div className="ListHeader">Created</div>
						</div>
						{jsonObj.map((user) => (
							<RenderUser
								key={user.userID}
								userID={user.userID}
								email={user.email}
								firstName={user.firstName}
								lastName={user.lastName}
								createdTimestamp={user.createdTimestamp}
							/>
						))}
						<p>&nbsp;</p>
					</div>
					<div className="ListContainer3">
						{prevYN && (<div 
							className="ListPrevNextLink"
							onClick={() => this.handlePrev()}
						>
							&lt;&lt; Previous
						</div>)}
						{!prevYN && (<div 
							className="ListPrevNextLinkDisabled"
						>
							&lt;&lt; Previous
						</div>)}
						<div className="ListPrevNextLinkDisabled">|</div>
						{nextYN && (<div 
							className="ListPrevNextLink"
							onClick={() => this.handleNext()}
						>
							Next &gt;&gt;
						</div>)}
						{!nextYN && (<div 
							className="ListPrevNextLinkDisabled"
						>
							Next &gt;&gt;
						</div>)}
					</div>
				</div>
			</div>
		);
	}
}

export default function UserListFunction(props) {
	const navigate = useNavigate();
	return <UserList {...props} navigate={navigate} />;
}
