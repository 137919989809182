import React  from 'react';
import './App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";

class CompanyNew extends React.Component {

	_input: ?HTMLInputElement;

	constructor(props) {

		super(props);
		
		this.state = {
			companyName: "",
		};

		this.onCompanyNameChanged = this.onCompanyNameChanged.bind(this);
		this.serverInsertCompany = this.serverInsertCompany.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState) {
		this._input.focus();
	}
	
	onCompanyNameChanged(value) {
		this.setState({companyName: value});
	}
	
	async serverInsertCompany(companyName) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertcompanyrecord?companyname=" + encodeURI(companyName);

		const res = await fetch(url);
		await res.text();

		const navigate = this.props.navigate;
  		navigate("/companies");
	}
		
	onClickSaveButton() {
		this.serverInsertCompany(this.state.companyName);
		toast("New company created", {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/companies");
	}
	
	render() {
		
		var managementAppUserType = this.props.managementAppUserType;
		if (managementAppUserType < 1000) {
			return null;
		}

		var saveButtonYN = false;
		
		if (this.state.companyName !== "") {
			saveButtonYN = true;
		}
		
		return (
			<div className="CompanyNewContainer1">

				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">New company</div>
				<div className="ViewTopText">Add company info and then proceed to add users</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Company info</div>
					<div style={{height: "20px"}}></div>
					<div className="CompanyNewContainer2">
						<p></p>
						<fieldset className="CompanyNewFieldset">
							<legend className="CompanyNewNameLegend">Company name</legend> 
							<input
								ref={c => (this._input = c)}
								className="CompanyNewNameInput" 
								id="companyName" 
								type="text" 
								autoFocus={true}
								onChange={(event) => this.onCompanyNameChanged(event.target.value)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="CompanyNewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton} disabled={!saveButtonYN}>Save</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function CompanyNewFunction(props) {
	const navigate = useNavigate();
	return <CompanyNew {...props} navigate={navigate} />;
}

/*
*/