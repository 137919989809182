import React  from 'react';
import './App.css';
  
import { useNavigate } from "react-router-dom";

class UserEmail extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			email: "",
		};
		
		this.onKeyDown = this.onKeyDown.bind(this);
		this.serverGetUserData = this.serverGetUserData.bind(this);		
		this.onEmailChanged = this.onEmailChanged.bind(this);		
		this.serverUpdateEmail = this.serverUpdateEmail.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount(){
		this.serverGetUserData();
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	async serverGetUserData() {

		var selectedUserID = this.props.selectedUserID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getuserdata?userid=" + selectedUserID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var email = jsonObj.email;

		this.setState({
			email: email,
		});
	}
	
	onEmailChanged(value) {
		this.setState({email: value});
	}
	
	async serverUpdateEmail(userID, email) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updateuseremail?userid=" + userID;
		url = url + "&email=" + encodeURI(email);
		
		//console.log("url = " + url);

		const res = await fetch(url);
		await res.text();

		const navigate = this.props.navigate;
  		navigate("/users/info");
	}
		
	onClickSaveButton() {
		this.serverUpdateEmail(this.props.selectedUserID, this.state.email);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/users/info");
	}
	
	render() {

		var managementAppUserType = this.props.managementAppUserType;
		if (managementAppUserType < 100) {
			return null;
		}

		var email = this.state.email;
		
		return (
			<div className="ViewContainer">
			
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">Update user's email</div>
				<div className="ViewTopText">Update email and press Save button</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">email</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewItemContainer">
						<div style={{height: "20px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">email</legend> 
							<input
								className="ViewItemInput" 
								id="email" 
								type="text" 
								autoFocus={true}
								defaultValue={email}
								onChange={(event) => this.onEmailChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>Save</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function UserEmailFunction(props) {
	const navigate = useNavigate();
	return <UserEmail {...props} navigate={navigate} />;
}


