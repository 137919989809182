import * as React from 'react';
import './App.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {storeEmailPassword} from './LoginLocalStorage.js';

import Topbar from './Topbar.js';
import Login from './Login.js';
import UserList from './UserList.js';
import UserNew from './UserNew.js';
import UserInfo from './UserInfo.js';
import UserName from './UserName.js';
import UserPhone from './UserPhone.js';
import UserEmail from './UserEmail.js';
import UserPassword from './UserPassword.js';
import CompanyList from './CompanyList.js';
import CompanyNew from './CompanyNew.js';
import CompanyUpdate from './CompanyUpdate.js';
import UserDBTouchInfo from './UserDBTouchInfo.js';

class App extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			baseURLServer: "https://management.plurato.com",
			baseURLAPI: "https://managementapi.plurato.com",
			dbtouchBaseURLAPI: "https://management.dbtouch.com",
			dbtouch: 0,
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			phone: "",
			managementAppUserType: 0,
			companyID: 0,
			companyName: "",
			createdTimestamp: "",
			selectedCompanyID: 0,
			selectedCompanyName: "",
			selectedUserID: 0,
			userListIndex: 0,
			firstLoadYN: true,
		}
		
		this.serverGetUserDataFromSessionID = this.serverGetUserDataFromSessionID.bind(this);
		this.getSessionIDFromURLString = this.getSessionIDFromURLString.bind(this);
		this.showToast = this.showToast.bind(this);
		this.logoutClearVariables = this.logoutClearVariables.bind(this);
		this.serverLogout = this.serverLogout.bind(this);
		this.serverGetUserData = this.serverGetUserData.bind(this);
		this.serverDBTouchGetUserData = this.serverDBTouchGetUserData.bind(this);
		this.setCompanyName = this.setCompanyName.bind(this);
		this.serverDBTouchSetCompanyName = this.serverDBTouchSetCompanyName.bind(this);
		this.serverGetUserDBTouchData = this.serverGetUserDBTouchData.bind(this);
		this.setSelectedCompanyName = this.setSelectedCompanyName.bind(this);
		this.setSelectedCompanyID = this.setSelectedCompanyID.bind(this);
		this.setSelectedUserID = this.setSelectedUserID.bind(this);
		this.setSessionID = this.setSessionID.bind(this);
		this.setUserID = this.setUserID.bind(this);
		this.setUserListIndex = this.setUserListIndex.bind(this);
		this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
	}
	
	componentDidMount(){

		document.title = "Plurato Management"

		var windowLocation = window.location;
		var sessionID = this.getSessionIDFromURLString(windowLocation.toString());
		
		if (sessionID === "") {
			this.setState({firstLoadYN: false});
			return;
		}
		
		this.serverGetUserDataFromSessionID(sessionID);
		this.setState({
			sessionID: sessionID,
		});
	}
	
	async serverGetUserDataFromSessionID(sessionID) {
		
		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getuserdatafromsessionid?sid=" + sessionID;

		try {
			const res = await fetch(url);
			const jsonObj = await res.json();

			var userID = jsonObj.userID;
			var email = jsonObj.email;
			var firstName = jsonObj.firstName;
			var lastName = jsonObj.lastName;
			var phone = jsonObj.phone;
			var managementAppUserType = jsonObj.managementAppUserType;
			var companyID = jsonObj.companyID;
			var createdTimestamp = jsonObj.createdTimestamp;

			this.setState({
				userID: userID,
				email: email,
				firstName: firstName,
				lastName: lastName,
				phone: phone,
				managementAppUserType: managementAppUserType,
				companyID: companyID,
				createdTimestamp: createdTimestamp,
				firstLoadYN: false,
			});
			
			this.setCompanyName(companyID);
		} catch (e) {
			this.setState({firstLoadYN: false});
		}
	}
	
	getSessionIDFromURLString(urlString) {

		var i;
		var index;
		var length;
		var foundYN;
		var sessionID = "";
		
		length = urlString.length;
		foundYN = false;
		index = 0;
		
		// find sid=
		for(i=0; (i<length) && (!foundYN); i++) {
			if (urlString.substr(i, 4) === "sid=") {
				foundYN = true;
				index = i+4;
			}
		}
		
		if (!foundYN) {
			return "";
		}
		
		sessionID = urlString.substr(index, length-index);
		return sessionID;
	}

	showToast(messageText) {
		toast(messageText, {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}
	
	logoutClearVariables() {

		var sessionID = this.state.sessionID;
		this.serverLogout(sessionID);

		this.setState({
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			phone: "",
			managementAppUserType: 0,
			companyID: 0,
			createdTimestamp: "",
			selectedCompanyID: 0,
			selectedCompanyName: "",
			selectedUserID: 0,
		});

		storeEmailPassword("", "");
	}

	async serverLogout(sessionID) {
		
		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/logout?sid=" + sessionID;

		const res = await fetch(url);
		await res.text();
	}

	async serverGetUserData(userID) {

		if (userID <= 0) {
			return;
		}

		var sessionID = this.state.sessionID;
		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getuserdata?sid=" + sessionID + "&userid=" + userID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var email = jsonObj.email;
		var firstName = jsonObj.firstName;
		var lastName = jsonObj.lastName;
		var phone = jsonObj.phone;
		var managementAppUserType = jsonObj.managementAppUserType;
		var companyID = jsonObj.companyID;
		var createdTimestamp = jsonObj.createdTimestamp;

		this.setState({
			dbtouch: 0,
			email: email,
			firstName: firstName,
			lastName: lastName,
			phone: phone,
			managementAppUserType: managementAppUserType,
			companyID: companyID,
			createdTimestamp: createdTimestamp,
		});
		
		this.setCompanyName(companyID);
	}
	
	async setCompanyName(companyID) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getcompanydata?companyid=" + companyID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var companyName = jsonObj.companyName;
		this.setState({
			companyName: companyName,
		});
	}
	
	async serverDBTouchGetUserData(userID) {

		if (userID <= 0) {
			return;
		}

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/dbtouchGetUserData?userid=" + userID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var isActive = jsonObj.isActive;
		if (!isActive) {
			this.logoutClearVariables();
			return;
		}

		var email = jsonObj.username;
		var firstName = jsonObj.firstName;
		var lastName = jsonObj.lastName;
		var companyID = jsonObj.companyId;
		
		this.setState({
			dbtouch: 1,
			email: email,
			firstName: firstName,
			lastName: lastName,
			companyID: companyID,
		});
		
		this.serverGetUserDBTouchData(email);
		this.serverDBTouchSetCompanyName(companyID);
	}
	
	async serverDBTouchSetCompanyName(companyID) {
	}
	
	async serverGetUserDBTouchData(email) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getuserdbtouchdata?email=" + encodeURIComponent(email);

		const res = await fetch(url);
		var responseText = await res.text();
		if (responseText === "") {
			return;
		}
		
		var jsonObj = JSON.parse(responseText);

		var language = jsonObj.language;
		var managementAppUserType = jsonObj.managementAppUserType;
		
		this.setState({
			language: language,
			managementAppUserType: managementAppUserType,
		});
}

	async setSelectedCompanyName(companyID) {

		var baseURLAPI = this.state.baseURLAPI;
		var url = baseURLAPI + "/getcompanydata?companyid=" + companyID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var companyName = jsonObj.companyName;
		this.setState({selectedCompanyName: companyName});
	}
	
	setSelectedCompanyID(selectedCompanyID) {
		this.setState({
			selectedCompanyID: selectedCompanyID,
			userListIndex: 0,
		});
		this.setSelectedCompanyName(selectedCompanyID);
	}
	
	setSelectedUserID(selectedUserID) {
		this.setState({selectedUserID: selectedUserID});
	}
	
	setSessionID(sessionID) {
		this.setState({sessionID: sessionID});
	}
	
	setUserID(userID) {
		this.setState({userID: userID});
	}
	
	setUserListIndex(userListIndex) {
		this.setState({userListIndex: userListIndex});
	}
	
	checkIfLoggedIn() {
				
		var pathname = window.location.pathname;
		
		if (pathname === "/") {
			return;
		}

		var userID = this.state.userID;
		if (userID !== 0) {
			return;
		}
		
		var url = "/";
		window.location.href = url;
	}
	
	render() {
		
		this.checkIfLoggedIn();

		var firstLoadYN = this.state.firstLoadYN;
		if (firstLoadYN) {
			return null;
		}

		var loggedInYN;
		var userID = this.state.userID;
		
		if (userID === 0) {
			loggedInYN = false;
		} else {
			loggedInYN = true;
		}
		
		return (
		<Router>
			<div className="App">
				<ToastContainer />
				<Topbar
					dbtouch={this.state.dbtouch}
					userID={this.state.userID}
					email={this.state.email}
					firstName={this.state.firstName}
					lastName={this.state.lastName}
					sessionID={this.state.sessionID}
					managementAppUserType={this.state.managementAppUserType}
					logoutClearVariables={this.logoutClearVariables}
				/>
        		<Routes>
          			<Route 
						path="/"
						element={
							<div>
							{!loggedInYN && (
								<Login
									baseURLAPI={this.state.baseURLAPI}
									setSessionID={this.setSessionID}
									setUserID={this.setUserID}
									serverGetUserData={this.serverGetUserData}
									serverDBTouchGetUserData={this.serverDBTouchGetUserData}
								/>
							)}
							{loggedInYN && (
								<UserList
									baseURLAPI={this.state.baseURLAPI}
									managementAppUserType={this.state.managementAppUserType}
									companyID={this.state.companyID}
									companyName={this.state.companyName}
									userListIndex={this.state.userListIndex}
									selectedCompanyID={this.state.selectedCompanyID}
									selectedCompanyName={this.state.selectedCompanyName}
									setSelectedUserID={this.setSelectedUserID}
									setUserListIndex={this.setUserListIndex}		
								/>
							)}
							</div>
						}
					/>
          			<Route 
						path="/login"
						element={
							<Login
								baseURLAPI={this.state.baseURLAPI}
								setSessionID={this.setSessionID}
								setUserID={this.setUserID}
								serverGetUserData={this.serverGetUserData}
								serverDBTouchGetUserData={this.serverDBTouchGetUserData}
							/>
						}
					/>
          			<Route 
						path="/users"
						element={
							<UserList
								baseURLAPI={this.state.baseURLAPI}
								managementAppUserType={this.state.managementAppUserType}
								companyID={this.state.companyID}
								companyName={this.state.companyName}
								userListIndex={this.state.userListIndex}
								selectedCompanyID={this.state.selectedCompanyID}
								selectedCompanyName={this.state.selectedCompanyName}
								setSelectedUserID={this.setSelectedUserID}
								setUserListIndex={this.setUserListIndex}		
							/>
						}
          			/>
          			<Route 
						path="/users/new"
						element={
							<UserNew
								baseURLAPI={this.state.baseURLAPI}
								selectedCompanyID={this.state.selectedCompanyID}
								selectedCompanyName={this.state.selectedCompanyName}
								managementAppUserType={this.state.managementAppUserType}
							/>
						}
          			/>
          			<Route 
						path="/users/info"
						element={
							<UserInfo
								baseURLAPI={this.state.baseURLAPI}
								managementAppUserType={this.state.managementAppUserType}
								selectedUserID={this.state.selectedUserID}
							/>
						}
          			/>
          			<Route 
						path="/users/name"
						element={
							<UserName
								baseURLAPI={this.state.baseURLAPI}
								managementAppUserType={this.state.managementAppUserType}
								selectedUserID={this.state.selectedUserID}
							/>
						}
          			/>
          			<Route 
						path="/users/phone"
						element={
							<UserPhone
								baseURLAPI={this.state.baseURLAPI}
								managementAppUserType={this.state.managementAppUserType}
								selectedUserID={this.state.selectedUserID}
							/>
						}
          			/>
          			<Route 
						path="/users/email"
						element={
							<UserEmail
								baseURLAPI={this.state.baseURLAPI}
								managementAppUserType={this.state.managementAppUserType}
								selectedUserID={this.state.selectedUserID}
							/>
						}
          			/>
          			<Route 
						path="/users/password"
						element={
							<UserPassword
								baseURLAPI={this.state.baseURLAPI}
								sessionID={this.state.sessionID}
								managementAppUserType={this.state.managementAppUserType}
								selectedUserID={this.state.selectedUserID}
							/>
						}
          			/>
          			<Route 
						path="/companies"
						element={
							<CompanyList
								baseURLAPI={this.state.baseURLAPI}
								managementAppUserType={this.state.managementAppUserType}
								setSelectedCompanyID={this.setSelectedCompanyID}
							/>
						}
          			/>
          			<Route 
						path="/companies/new"
						element={
							<CompanyNew
								baseURLAPI={this.state.baseURLAPI}
								managementAppUserType={this.state.managementAppUserType}
							/>
						}
          			/>
          			<Route 
						path="/companies/update"
						element={
							<CompanyUpdate
								baseURLAPI={this.state.baseURLAPI}
								managementAppUserType={this.state.managementAppUserType}
								selectedCompanyID={this.state.selectedCompanyID}
							/>
						}
          			/>
          			<Route 
						path="/dbtouchuser"
						element={
							<UserDBTouchInfo
								baseURLAPI={this.state.baseURLAPI}
								dbtouchBaseURLAPI={this.state.dbtouchBaseURLAPI}
								managementAppUserType={this.state.managementAppUserType}
							/>
						}
          			/>
					
        		</Routes>
			</div>
    		</Router>
		);
	}
}

export default App;

/*
*/

