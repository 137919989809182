import React  from 'react';
import './App.css';
import './TopbarPopupMenu.css';
import { useNavigate } from "react-router-dom";

class TopbarPopupMenu extends React.Component {

	constructor(props) {

		super(props);
		this.ref = React.createRef();

		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.onAccountButtonClicked = this.onAccountButtonClicked.bind(this);
		this.onLogoutButtonClicked = this.onLogoutButtonClicked.bind(this);
	}

	handleClickOutside(event) {
		if (this.ref.current && !this.ref.current.contains(event.target)) {
			this.props.closePopupMenu();
		}
	};

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside.bind(this), true);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside.bind(this), true);
	};

	onAccountButtonClicked() {

		var dbtouch = this.props.dbtouch;

		var sessionID = this.props.sessionID;
		var accountURL;
		
		if (dbtouch === 0) {
			accountURL = "https://apps.plurato.com/myaccount?sid=" + encodeURI(sessionID);
		} else {
			accountURL = "https://management.dbtouch.com";
		}

		const newWindow = window.open(accountURL, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
		this.props.closePopupMenu();
	}
	
	onLogoutButtonClicked() {
		this.props.logoutClearVariables();
		const navigate = this.props.navigate;
  		navigate("/login");
		this.props.closePopupMenu();
	}
	
	render() {
		
		var email = this.props.email;
		
		return (
			<div ref={this.ref} className="TopbarPopupMenuContainer1">
				<div className="TopbarPopupMenuArrow"></div>
				<div className="TopbarPopupMenuEmailText">{email}</div>
				<div style={{height: "15px"}}></div>
				<button className="TopbarPopupMenuAccountButton" onClick={this.onAccountButtonClicked}>My Account</button>
				<div style={{height: "15px"}}></div>
				<button className="TopbarPopupMenuLogoutButton" onClick={this.onLogoutButtonClicked}>Logout</button>
			</div>
		);
	}
}

export default function TopbarPopupMenuFunction(props) {
	const navigate = useNavigate();
	return <TopbarPopupMenu {...props} navigate={navigate} />;
}
