import React  from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";

class CompanyListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			selectedTab: "",
		};
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(tab) {
		this.setState({selectedTab: tab});
		const navigate = this.props.navigate;
  		navigate("/companies/new");
	}
	
	renderLink(linkTitle, linkValue) {

		var selectedTab = this.state.selectedTab;
		var classNameString = "CompanyListToolbarLink";
		
		if (selectedTab === linkValue) {
			classNameString = "CompanyListToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {
		return (
			<div className="CompanyListToolbarContainer1">
				<div className="CompanyListToolbarLeft">&nbsp;</div>
				<div className="CompanyListToolbarCenter">&nbsp;</div>
				<div className="CompanyListToolbarRight">
					<div className="CompanyListToolbarContainer2">
						{this.renderLink("New company", "companyadd")}
					</div>
				</div>
			</div>
		);
	}
}

export default function CompanyListToolbarFunction(props) {
	const navigate = useNavigate();
	return <CompanyListToolbar {...props} navigate={navigate} />;
}

