import React  from 'react';
import './App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  
import { useNavigate } from "react-router-dom";

class CompanyUpdate extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			companyName: "",
		};

		this.onCompanyNameChanged = this.onCompanyNameChanged.bind(this);		
		this.serverGetCompanyData = this.serverGetCompanyData.bind(this);
		this.serverUpdateCompany = this.serverUpdateCompany.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount() {
		this.serverGetCompanyData(this.props.selectedCompanyID);
	}
	
	onCompanyNameChanged(value) {
		this.setState({companyName: value});
	}
	
	async serverGetCompanyData(companyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getcompanydata?companyid=" + companyID;

		const res = await fetch(url);
		const jsonObj = await res.json();
		
		var companyName = jsonObj.companyName;
		this.setState({companyName: companyName});
	}
		
	async serverUpdateCompany(companyID, companyName) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/updatecompanydata?companyid=" + companyID + "&companyname=" + encodeURI(companyName);

		const res = await fetch(url);
		await res.text();

		const navigate = this.props.navigate;
  		navigate("/companies");
	}
		
	onClickSaveButton() {
		toast("Company info updated", {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
		this.serverUpdateCompany(this.props.selectedCompanyID, this.state.companyName);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/companies");
	}
	
	render() {

		var managementAppUserType = this.props.managementAppUserType;
		if (managementAppUserType < 1000) {
			return null;
		}

		var saveButtonYN = false;
		var companyName;
		
		if (this.state.companyName !== "") {
			saveButtonYN = true;
		}
		
		companyName = this.state.companyName;

		return (
			<div className="CompanyNewContainer1">
			
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">Update company</div>
				<div className="ViewTopText">Update company info and then press Save button</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">Company info</div>
					<div style={{height: "20px"}}></div>
					<div className="CompanyNewContainer2">
						<p></p>
						<fieldset className="CompanyNewFieldset">
							<legend className="CompanyNewNameLegend">Company name</legend> 
							<input
								ref={c => (this._input = c)}
								className="CompanyNewNameInput" 
								id="companyName" 
								type="text" 
								autoFocus={true}
								defaultValue={companyName}
								onChange={(event) => this.onCompanyNameChanged(event.target.value)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="CompanyNewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>Cancel</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton} disabled={!saveButtonYN}>Save</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function CompanyUpdateFunction(props) {
	const navigate = useNavigate();
	return <CompanyUpdate {...props} navigate={navigate} />;
}


