import React  from 'react';
import './App.css';

import { useNavigate } from "react-router-dom";

import CompanyListToolbar from './CompanyListToolbar.js';

class CompanyList extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			jsonObj: null,
			companyCount: 0,
			index: 0,
			count: 10,
		};

		this.serverGetCompanyList = this.serverGetCompanyList.bind(this);
		this.handleSelectCompany = this.handleSelectCompany.bind(this);
		this.formatDateTimeString = this.formatDateTimeString.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);
	}
	
	componentDidMount() {
		this.serverGetCompanyList(0);
	}
	
	async serverGetCompanyList(index) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getcompanylist?";
		url = url + "sortby=companyName&sortasc=1";
		url = url + "&index=" + index + "&count=" + this.state.count;

		const res = await fetch(url);
		const jsonObj = await res.json();
		var companyCount = jsonObj.length;

		this.setState({
			jsonObj: jsonObj,
			companyCount: companyCount,
		});
	}
		
	handleSelectCompany(companyID) {
		this.props.setSelectedCompanyID(companyID);
		const navigate = this.props.navigate;
  		navigate("/companies/update");
	}

	formatDateTimeString(timestamp) {
		
		var timestampString;
		
		var dateString = timestamp.substr(0, 10);
		var timeString = timestamp.substr(11, 8);

		timestampString = dateString + " " + timeString;
		
		return timestampString;
	}
	
	handlePrev() {
		var index = this.state.index - 10;
		this.setState({index: index});
		this.serverGetCompanyList(index);
	}

	handleNext() {
		var index = this.state.index + 10;
		this.setState({index: index});
		this.serverGetCompanyList(index);
	}
	
	render() {
		
		const RenderCompany = ({ companyID, companyName, createdTimestamp }) => (
			<div className="ListRow">
				<div
					className="ListLink"
					onClick={() => this.handleSelectCompany(companyID)}
				>
					{companyName}
				</div>
				<div 
					className="ListLink"
					onClick={() => this.handleSelectCompany(companyID)}
				>
					{this.formatDateTimeString(createdTimestamp)}
					{}
				</div>
			</div>
		);

		var managementAppUserType = this.props.managementAppUserType;
		if (managementAppUserType < 1000) {
			return null;
		}

		var jsonObj = this.state.jsonObj;
		
		if (jsonObj === null) {
			return (<div></div>)
		}
		
		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (jsonObj.length === 10) {
			nextYN = true;
		}
		
		return (
			<div>
				<CompanyListToolbar />
				<div className="ListContainer1">
					<div className="ListContainer2">
						<div className="ListRow">
							<div className="ListHeader">&nbsp;</div>
						</div>
						<div className="ListRow">
							<div className="ListHeader">Company Name</div>
							<div className="ListHeader">Created</div>
						</div>
						{jsonObj.map((company) => (
							<RenderCompany
								key={company.companyID}
								companyID={company.companyID}
								companyName={company.companyName}
								createdTimestamp={company.createdTimestamp}
							/>
						))}
						<p>&nbsp;</p>
					</div>
					<div className="ListContainer3">
						{prevYN && (<div 
							className="ListPrevNextLink"
							onClick={() => this.handlePrev()}
						>
							&lt;&lt; Previous
						</div>)}
						{!prevYN && (<div 
							className="ListPrevNextLinkDisabled"
						>
							&lt;&lt; Previous
						</div>)}
						<div className="ListPrevNextLinkDisabled">|</div>
						{nextYN && (<div 
							className="ListPrevNextLink"
							onClick={() => this.handleNext()}
						>
							Next &gt;&gt;
						</div>)}
						{!nextYN && (<div 
							className="ListPrevNextLinkDisabled"
						>
							Next &gt;&gt;
						</div>)}
					</div>
				</div>
			</div>
		);
	}
}

export default function CompanyListFunction(props) {
	const navigate = useNavigate();
	return <CompanyList {...props} navigate={navigate} />;
}


