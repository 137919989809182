import React  from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";

class UserListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			selectedTab: "",
		};
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(tab) {
		this.setState({selectedTab: tab});
		const navigate = this.props.navigate;
  		navigate("/users/new");
	}
	
	renderLink(linkTitle, linkValue) {

		var selectedTab = this.state.selectedTab;
		var classNameString = "UserListToolbarLink";
		
		if (selectedTab === linkValue) {
			classNameString = "UserListToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {
		return (
			<div className="UserListToolbarContainer1">
				<div className="UserListToolbarLeft">&nbsp;</div>
				<div className="UserListToolbarCenter">&nbsp;</div>
				<div className="UserListToolbarRight">
					<div className="UserListToolbarContainer2">
						{this.renderLink("New user", "useradd")}
					</div>
				</div>
			</div>
		);
	}
}

export default function UserListToolbarFunction(props) {
	const navigate = useNavigate();
	return <UserListToolbar {...props} navigate={navigate} />;
}

